import React, {useContext} from 'react'
import './Portfolio.css'
import  {Swiper, SwiperSlide} from 'swiper/react'
import "swiper/css";
import Shoppy from "../../img/shoppy.png";
import FitClub from '../../img/FitClub.png'
import Movie from "../../img/movie.png";
import Weather from '../../img/weather.png';
import Play2learn from '../../img/play2learn.png';
import Anagram from '../../img/anagram.png';

import { themeContext } from "../../Context";

const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio">
      {/*heading*/}
      <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
      <span>Portfolio</span>

      {/*slider*/}
      <Swiper
      spaceBetween={30}
      slidesPerView={3}
      grabCursor={true}
      className="portfolio-slider"
      >
      <SwiperSlide>
          <a href='https://merry-robbins-dashboard.netlify.app'>
          <img src={Shoppy} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='https://fitclubmerryrobbins.pages.dev/'>
          <img src={FitClub} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='https://merrys-anagram.netlify.app/'>
          <img src={Anagram} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          
          <img src={Movie} alt="" />
          
        </SwiperSlide>
        <SwiperSlide>
          
          <img src={Weather} alt="" />
          
        </SwiperSlide>
        <SwiperSlide>
          
          <img src={Play2learn} alt="" />
          
        </SwiperSlide>
      </Swiper>

    </div>
  )
}

export default Portfolio