import React, {useContext} from 'react'
import './Experience.css'
import { themeContext } from "../../Context";

const Experience = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='experience'>
        <div className="achievement">
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>7+</div>
        <span  style={{color: darkMode?'white':''}}>programing</span>
        <span>languages</span>
        
      </div>
        <div className='achievement'>
            <div className="circle">1+</div>
            <span>year</span>
            <span>experience</span>
        </div>
        <div className="achievement">
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>10+</div>
        <span  style={{color: darkMode?'white':''}}>completed </span>
        <span>Projects</span>
      </div>
      

    </div>
    
   
  )
}

export default Experience