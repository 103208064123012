import React from 'react'
import './Intro.css'
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
import girl from '../../img/girl.png'
import glassesimoji from "../../img/glassesimoji.png";
import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import Github from '../../img/github.png'
import LinkedIn from '../../img/linkedin.png';
import Instagram from '../../img/instagram.png';
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const Intro = () => {
  const transition = { duration: 2, type: "spring" };
  return (
    <div className="intro">
        <div className="i-left">
            <div className="i-name">
                <span>Hi! I am</span>
                <span>Merry Robbins</span>
                <span>Frontend Developer with experience in web development and producing quality websites using HTML, CSS, JAVASCRIPT, PYTHON </span>
            </div>
            <Link to="contact" smooth={true} spy={true}>
            <button className='button i-button'>Hire me</button>
            </Link>
            <div className="i-icons">
              <img src={Github} alt="" />
              <img src={LinkedIn} alt="" />
              <img src={Instagram} alt="" />
              </div>
        </div>
        <div className="i-right">
          <img src={Vector1} alt="" />
          <img src={Vector2} alt="" />
          <img src={girl} alt="" />
          <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          src={glassesimoji}
          alt=""
        />
         
          <div style={{top: '-4%', left: '68%'}}>
          <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
            <FloatingDiv  image={crown} txt1= 'Web' txt2="Developer"/>
            </motion.div>
          </div>
          <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
          <FloatingDiv image={thumbup} txt1='Best Design' txt="Award"/>
          </motion.div>
          {/* blur divs*/}
          <div className='blur' style={{background: "rgb(238 210 255)"}}></div>
          <div className="blur"
          style={{background: '#C1F5FF',
          top: '17rem',
          width: '21rem',
          height: '11rem',
          left: '-9rem'}}
          ></div>
        </div>
    </div>
  )
}

export default Intro